a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: black;
}

body {
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Loader */
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  width: 100%;
  text-align: center;
  padding: 1em;
  margin: 0 auto;
}
.loader .spinner {
  fill: #5765ea;
}

/* NavBar */
.navbar {
  background: transparent;
  height: 60px;
  margin: 10px;
  text-transform: uppercase;
  /* border-bottom: 1px solid #eee; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar a {
  padding: 8px 15px;
  font-size: 13px;
}
.navbar a.active {
  background: rgb(54, 24, 226);
  border-radius: 4px;
  color: #fff;
  transition: all 0.5s;
}

.navbar .logo {
  display: flex;
  flex-direction: row;
  font-size: 40px;
}

.logo > a > img{
  height: 60px;
}

.logo > div {
  display: flex;
  align-items: center;
}
/* Switch-Languages */
.switch-languages button {
  border: 0;
  border-radius: 2px;
  background: transparent;
  cursor: pointer;
  color: #999;
}
.switch-languages button.active {
  color: white;
  background: #222;
  transition: all 0.5s;
}

/* main */
main {
  padding: 30px 10px;
  font-size: 14px;
  color: #444;
  /* height: 100vh; */
}
.page-title {
  text-transform: capitalize;
}

/* Footer */
footer {
  /* height: 120px; */
  background: #333;
  padding: 10px;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
}
#footer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  background: local;
  font-size: 20px;
}
#copyright {
  padding-top: 20px;
  background: local;
  color: white;
  font-size: 12px;
}


.nav-link {
  border: 1px solid;
  border-radius: 4px;
  margin-right: 10px;
}
.nav-link.active {
  background: rgb(54, 24, 226);
  color: #fff;
  transition: all 0.5s;
}

#tags {
  padding-bottom: 20px;
}